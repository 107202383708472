// styles.js
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    gap: ${theme.spacings.xxl};
    padding: 6.4rem ${theme.spacings.md} 10rem;
    background-color: ${theme.colors.base['200']};

    img {
      max-width: 100%;
      height: auto;
    }
  `}
`;

export default Wrapper;
