import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import Text from 'components/foundation/Text';

import ButtonWrapper from './styles';

const Button = ({
  title,
  onClick,
  typography,
  externalUrl,
  size = 'full',
  center = false,
  themeColor = 'secondaryTheme',
  ...restProps
}) => {
  const content = (
    <ButtonWrapper
      $size={size}
      center={center ? true : undefined}
      onClick={onClick}
      $themeColor={themeColor}
      {...restProps}
    >
      <Text
        as='span'
        $csscolor='00'
        $cssTextAlign='center'
        $variant={{ typography: typography ?? 'bodyTextBold' }}
      >
        {title}
      </Text>
    </ButtonWrapper>
  );

  return externalUrl ? (
    <Link
      target='_top'
      href={externalUrl}
    >
      {content}
    </Link>
  ) : (
    content
  );
};

Button.propTypes = {
  center: PropTypes.bool,
  onClick: PropTypes.func,
  themeColor: PropTypes.string,
  revertColors: PropTypes.bool,
  typography: PropTypes.string,
  externalUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['medium', 'full']),
};

export default React.memo(Button);
