import React from 'react';

import { getMenus } from 'libs/api';

import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';

import BetPageHOC from 'components/wrapper/BetPage/hoc';
import ErrorPage from 'components/pages/ErrorPage';

const Page404 = () => <ErrorPage imageSrc='/images/404.png' />;

Page404.propTypes = {};

export async function getStaticProps() {
  // Definindo propriedade de SEO
  const seoProps = {
    robots: 'noindex, nofollow',
    title: 'Página não Encontrada | Game Arena',
    url: `${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}/404`,
    description: 'Página não Encontrada',
  };

  const dataMenus = await getMenus({ ssr: true });

  return {
    props: {
      pageWrapperProps: {
        seoProps,
        menuProps: {
          display: true,
          menus: dataMenus?.menu || {},
        },
      },
    },
    revalidate: VARIAVEIS_GLOBAIS.TIMES.TEN_DAYS_SECONDS,
  };
}

export default BetPageHOC(React.memo(Page404), {
  pageWrapperProps: {
    seoProps: {
      robots: 'noindex, nofollow',
    },
    footerProps: {
      cssProps: {
        marginTop: '0 !important',
      },
      display: true,
    },
  },
});
