import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';

import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';

import Text from 'components/foundation/Text';
import Button from 'components/common/Button';

import Wrapper from './styles';

const ErrorPage = ({
  showButton = true,
  title = 'Pra fooora!',
  imageSrc = '/images/error-thumb.png',
  description = 'Página não encontrada. Ela pode ter sido removida ou nunca existiu.',
}) => (
  <Wrapper>
    <Image
      alt='404'
      width={472}
      height={254}
      src={imageSrc}
    />
    <Text
      as='h2'
      $csscolor='950'
      $variant={{ typography: 'heading1' }}
    >
      {title}
    </Text>
    <Text
      as='p'
      $csscolor='950'
      $variant={{ typography: 'bodytext' }}
    >
      {description}
    </Text>
    {showButton ? (
      <Button
        size='medium'
        themeColor='gradientTheme'
        title='IR PARA PÁGINA INICIAL'
        externalUrl={VARIAVEIS_GLOBAIS.HOST_GAMEARENA}
      />
    ) : null}
  </Wrapper>
);

ErrorPage.propTypes = {
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  showButton: PropTypes.bool,
  description: PropTypes.string,
};

export default React.memo(ErrorPage);
